import ReactGA from "react-ga4";
import type { CookieConsentConfig } from "vanilla-cookieconsent";
import { acceptedCategory } from "vanilla-cookieconsent";


const pluginConfig: CookieConsentConfig = {
    guiOptions: {
        consentModal: {
            layout: "box",
            position: "bottom left",
            equalWeightButtons: true,
            flipButtons: false,
        },
        preferencesModal: {
            layout: "box",
            position: "right",
            equalWeightButtons: true,
            flipButtons: false,
        },
    },

    onChange: ({ changedCategories, cookie }) => {
        if (changedCategories.includes("analytics")) {
            if (acceptedCategory("analytics")) {
                ReactGA.gtag("consent", "update", {
                    analytics_storage: "granted",
                });
            } else {
                ReactGA.gtag("consent", "update", {
                    analytics_storage: "denied",
                });
            }
        }

        if (changedCategories.includes("advertising")) {
            if (acceptedCategory("advertising")) {
                ReactGA.gtag("consent", "update", {
                    ad_storage: "granted",
                    ad_personalization: "granted",
                });
            } else {
                ReactGA.gtag("consent", "update", {
                    ad_storage: "denied",
                    ad_personalization: "denied",
                });
            }
        }
    },
    categories: {
        necessary: {
            readOnly: true,
        },
        advertising: {},
        analytics: {},
    },

    language: {
        default: "en",
        autoDetect: "browser",
        translations: {
            en: {
                consentModal: {
                    title: "Hello!",
                    description:
                        "We use cookies to personalise content and advertisements, to offer social media functions and to analyse access to our website. We also share information about your use of our website with our social media, advertising and analytics partners. Our partners may combine this information with other data that you have provided to them or that they have collected as part of your use of the services. \nFurther information: Privacy policy / Imprint ",
                    closeIconLabel: "",
                    acceptAllBtn: "Accept all",
                    acceptNecessaryBtn: "Reject all",
                    showPreferencesBtn: "Manage preferences",
                    footer: '<a href="#link">Privacy Policy</a>\n<a href="#link">Terms and conditions</a>',
                },
                preferencesModal: {
                    title: "Consent Preferences Center",
                    closeIconLabel: "Close modal",
                    acceptAllBtn: "Accept all",
                    acceptNecessaryBtn: "Reject all",
                    savePreferencesBtn: "Save preferences",
                    serviceCounterLabel: "Service|Services",
                    sections: [
                        {
                            title: "Cookie declaration",
                            description:
                                "Cookies are small text files used by websites to make the user experience more efficient. By law, we can store cookies on your device if they are strictly necessary for the operation of this site. For all other types of cookies, we need your permission. This site uses different types of cookies. Some cookies are placed by third parties that appear on our pages.\nYou can change or withdraw your consent at any time from the cookie statement on our website. ",
                            linkedCategory: "necessary",
                        },
                        {
                            title: 'Strictly Necessary Cookies <span class="pm__badge">Always Enabled</span>',
                            description:
                                "Mandatory field - cannot be deselected. Necessary cookies help to make the videos on the website usable by enabling basic functions such as page navigation and access to secure areas of the website. Playing the videos cannot function properly without these cookies. ",
                            linkedCategory: "necessary",
                        },
                        {
                            title: "Marketing Cookies",
                            description:
                                'Marketing-Cookies erfassen Informationen über das Online-Verhalten von Besuchern, um die Auslieferung von Werbung zu optimieren. Sie zielen darauf ab, Werbeinhalte anzuzeigen, die für die Präferenzen des Nutzers potenziell interessanter sind, und unterstützen so eine effizientere Werbekommunikation zwischen Webseitenbetreibern und Nutzern.\n\nBei Fragen zu den Cookies und den Wahlmöglichkeiten wende dich bitte an <a class="cc__link" href="#info@rico-brunner.com">uns</a>.',
                            linkedCategory: "advertising",
                        },
                    ],
                },
            },
            autoDetect: "browser",
        },
    },
};

export default pluginConfig;
