"use client";

import type React from "react";
import { useEffect } from "react";
import ReactGA from "react-ga4";

const initializeGA = () => {
    ReactGA.initialize("G-R7KR8LKNHW");
    ReactGA.gtag("consent", "default", {
        ad_storage: "denied",
        ad_user_data: "denied",
        ad_personalization: "denied",
        analytics_storage: "denied",
    });
};

const GoogleAnalytics: React.FC = () => {
    useEffect(() => {
        // @ts-ignore
        if (!window.GA_INITIALIZED) {
            initializeGA();
            // @ts-ignore
            window.GA_INITIALIZED = true;
        }
    }, []);

    return null;
};

export default GoogleAnalytics;
